<template>
  <div class="footer-container">
    <div class="footer-content">
      <div class="social-contact mobile">
        <a href="">
          <img src="../Imagenes/icono_tw.png" alt="">
        </a>
        <a href="https://www.linkedin.com/company/altera-academy" alt="linkedin" target="_blank">
          <img src="../Imagenes/icono_in.png" alt="">
        </a>
        <a href="">
          <img src="../Imagenes/icono_ig.png" alt="">
        </a>
      </div>
      <div class="footer-one">
        <div class="footer-logo">
          <router-link to="/"><img src="../Imagenes/logo_color.png" alt=""></router-link>
        </div>
        <div class="footer-enlaces">
          <p>Enlaces de ayuda</p>
          <a @click="showSendModal">Centro de ayuda</a><br>
          <router-link to="/politicas">Políticas de privacidad</router-link><br>
          <router-link to="/terminos">Términos y condiciones</router-link><br>
        </div>
      </div>
      <div class="footer-two">
        <div class="btn-work">
          <router-link to="/form_job">Trabaja con nosotros</router-link>
        </div>
        <div>
          <div class="search_box" @keydown.enter="searchCourses">
            <input class="search" type="text" placeholder="Buscar" v-model="search" >
            <div class="search_img">
              <img src="../Imagenes/lupa.png" alt="buscar">
            </div>
          </div>
          <div class="social-contact portrait">
            <a href="">
              <img src="../Imagenes/icono_tw.png" alt="">
            </a>
            <a href="https://www.linkedin.com/company/altera-academy" alt="linkedin" target="_blank">
              <img src="../Imagenes/icono_in.png">
            </a>
            <a href="">
              <img src="../Imagenes/icono_ig.png" alt="">
            </a>
          </div>
        </div>
      </div>
    </div>
    <div v-show="showModal" class="modal-bc-contact">
      <div class="modal-container">
        <a @click="showSendModal" class="ClosePopUp">
          <img src="../Imagenes/cerrar.png" alt="cerrar-popUp">
        </a>
        <div class="contact-title">
          <div class="contact-title-box">
            <h5>Bienvenido al centro de ayuda</h5>
            <p>Ingresa tus datos y en breve nos comunicaremos contigo.</p>
          </div>
          <div class="logo-box">
            <img src="../Imagenes/logo-gris.png" alt="Logo Altera">
          </div>
        </div>
        <div class="contact-content">
          <div class="content-box1">
            <div>
              <p>Nombre</p>
              <input id="name"  type="text" v-model="name" name="name" placeholder="Ingresa tu nombre">
            </div>
            <div>
              <p>Mail</p>
              <input id="email" type="text" v-model="email" name="email" placeholder="Ingresa tu mail">
            </div>
            <div>
              <p>Teléfono</p>
              <input id="phone" type="text" v-model="phone"  name="phone" placeholder="Ingresa tu número">
            </div>
          </div>
          <div class="content-box2">
            <div>
              <p>Mensaje</p>
              <textarea id="message" name="" v-model="message"  cols="30" rows="10" placeholder="¿Cómo podemos ayudarte?"></textarea>
            </div>
          </div>
        </div>
        <div class="btns-contact">
          <input @click="checkForm" type="submit" value="Enviar">
        </div>
        <div class="error">
          <p v-if="errors.length">
            <ul>
              <li v-for="(error,index) in errors" :key="index">{{ error }}</li>
            </ul>
          </p>
        </div>
      </div>
    </div>
    <div class="footer-final">
      <p>Copyright © Altera Academy 2021 - Todos los derechos reservados</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Footer',
  props: {},
  data() {
    return {
      showModal: false,
      search: '',
      name: '',
      email: '',
      subject: 'Centro de Ayuda',
      message: '',
      phone:'',
      errors: [],
      regxEmail: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      regxPhone:/[^0-9\\+]/g
    }
  },
  methods: {
    showSendModal(){
    this.showModal = !this.showModal
    },
    searchCourses() {    
        let search = this.search.trim().toLowerCase()
        if(this.$route.path !== '/cursos/') {
          window.location = `/cursos/?search=${search}`
        } else {
          this.$emit('search-course', search)
          this.search = ''
        }
    },
    sendInformation(){
      // Mostrar mensaje "Enviando..."
      let data = {
          "to": "contacto@alteraacademy.com", 
          "from": this.email,
          "sender":this.name,
          "subject": this.subject,
          "phone": this.phone,
          "msg":  this.subject + '<br>' +'Nombre: ' + this.name + ' <br> Correo: ' + this.email + ' <br> Teléfono: ' + this.phone + ' <br> Mensaje: ' + this.message,
          }
          axios
          .post('https://sender.alteracloud.com/send',data)
          .then((response)=>{
            console.log(response.data);
            this.showModal = !this.showModal;
            this.email="";
            this.name="";
            this.message="";
            this.phone="";
            this.$router.push({ name: "confirmacion"})
          })
          .catch((error)=>{
            // Si esto ocurre mostrar un mensaje de "Fallo en el servidor", el cual deberia desaparecer unos segundos despues
            console.log(error)
            })
    },
    checkForm() {
              // Falta bloquear el boton cuando se está procesando y desbloquearlo cuando termine.
              this.errors = [];
              if ((!this.name)||(!this.email)||(!this.message)||(!this.phone))
              {
                  this.errors.push('Uno o más campos presentan un error');
              } 
              if (this.name.length <6 || this.email.length <6 || this.message.length <6 || this.phone.length <9 ) {
                  this.errors.push('Sus datos deben tener como  mínimo 5 caracteres');
              }
              if (!this.errors.length) {
                  this.sendInformation();
              } else {
                  return false;
              }
    }  
  } 
}
</script>

<style scoped>
.footer-container{
  background-color: #ffff;
}
.footer-content{
  padding: 3rem 1rem;
  color: #62657a;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
}
.footer-one{
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
}
.footer-logo img{
  width: 10rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.footer-enlaces{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.footer-enlaces p{
  font-weight: 600;
  font-size: 14px;
}
.footer-enlaces a{
  color:#62657a;
  font-size: 14px;
}
.footer-final{
  background-color: #41455d;
}
.footer-final p{
 font-size: 10px;
 color: #4ff5dd;
 margin: 0;
}
.btn-work{
  border: 1px solid #5359c1;
  border-radius: 20px;
  width: 190px;
  padding: 0.3rem;
  margin: 2rem 0rem;
}
.btn-work a{
  text-decoration: none;
  color: #62657A;
  font-size: 14px;
  font-weight: 600;
}
.footer-two{
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
}
/*.search{
  color: #fff;
  padding: 0.3rem 2rem;  
}*/
.search_box{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #41455d;
  border-radius: 20px;
  width: 230px;
  height: 30px;
}
input.search{
  background-color: #41455d;
  border: 1px solid #41455d;
  border-radius: 20px;
  width: 80%;
  color: #fff;
  padding: 0rem 1rem;
  font-size: 14px;
}

.search::placeholder{
  color: #fff;
  padding: 0rem 1rem;
  font-size: 14px;
}
.search_img{
  background-color: #525bb7;
  height: 30px;
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border:2px solid #525bb7;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.search_img img{
  width: 1.1em;
}
.social-contact{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.social-contact a img{
  width: 2.5rem;
  margin: 1rem 0.5rem;
}

/*modal*/
.ClosePopUp{
  width: 102%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.ClosePopUp img{
  width: 2rem;
  margin-top: -15px;
}
.modal-bc-contact{
  position: fixed;
  z-index: 900;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal-container{
  background-color: #fff;
  border-radius: 30px;
}
.modal-container input{
  background-color: #dddd;
  border: 1px solid #dddd;  
  border-radius: 20px;
  padding: 0.2rem 1rem;
  margin-bottom: 1rem;
  width: 250px;
}
.modal-container textarea{
  background-color: #dddd;
  border: 1px solid #dddd;  
  border-radius: 20px;
  padding: 0.2rem 1rem;
  height: 110px;
  width: 250px;
}
.modal-container input::placeholder,
.modal-container textarea::placeholder{
  font-size: 12px;
}
.contact-title{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: initial;
  padding: 0.5rem 2.5rem 1rem 2.5rem;
}
.contact-title-box h5{
  color: #6154b4;
  font-weight: 600;
}
.contact-title-box p{
  color: #808393;
  font-size: 10px;
}
.contact-content{
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: initial;
  padding: 0rem 2.5rem 0rem 2.5rem;
}
.contact-content p{
  color:#4d4d4e ;
  margin-bottom: 5px;
  font-size: 12px;
}
.logo-box img{
  width: 2rem;
}
.btns-contact input{
  border: 1.5px solid #4ff5dd;
  background-color:#4ff5dd ;
  border-radius: 20px;
  width: 100px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #525bb7;
  font-weight: 600;
}
.btns-box{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.footer-enlaces a{
  text-decoration: underline;
  cursor: pointer;
}
.error{
  width: 100%;
  background-color: #f2f2f2;
  font-size: 10px;
  margin-bottom: 2rem;
}
.error ul li{
  list-style: none;
  color: #000;
  text-align: center;
}
input:focus,
input:active {
  outline: none;
}
@media (max-width: 992px){}
@media (min-width: 992px){
  .mobile{
    display: none;
  }
  .footer-content{
    padding: 1.5rem 1rem;
    flex-direction: row;
    justify-content: space-around;
  }

  .footer-one{
    flex-direction: row;
    width: 50%;
    justify-content: space-evenly;
  }
  .footer-two{
    flex-direction: row;
    width: 50%;
    justify-content: space-evenly;
  }
  .footer-enlaces{
    align-items: end;
  }
  .contact-content{
    flex-direction:row;
    
  }
  .contact-title-box{
    text-align: left;
  }
  .modal-container textarea{
    height: 180px;
  }
  .content-box1{
    text-align: left;
  }
  .content-box2{
    margin-left:2rem ;
    text-align: left;
  }
}
</style>