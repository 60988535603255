<template>
  <div>
    <div class="portrait">
    <nav class="navbar navbar-expand-lg navbar-light ">
            <div class="logoAA">
                <router-link to="/"><img src="../Imagenes/logo-blanco.png" alt="logo"></router-link> 
            </div>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <br><ul class="navbar-nav mr-auto">
                    <li class="nav-item">
                        <router-link to="/" class="nav-link">Inicio</router-link>      
                    </li>
                    <li class="nav-item">
                        <router-link to="/#quienes-somos" class="nav-link" >Quiénes Somos</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/#cursos" class="nav-link" >Cursos</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/servicios" class="nav-link" >Servicios</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/#blog_" class="nav-link" >Blog</router-link>
                    </li>
                    <li class="nav-item btn_nav">
                        <router-link to="/#contacto_" class="nav-link">Contáctanos</router-link>
                    </li>
                </ul>
            </div> 
        </nav>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'Menu',
  props: { }
}
</script>

<style scoped>
.logoAA img{
    width: 9rem;
}
.navbar-light .navbar-toggler {
    border-color: #525bb7;
}
.nav-link{
    padding: 0.1rem;
}
.navbar-light .navbar-nav .nav-link {
    color: #fff;
}

.navbar{
    position: fixed;
    background-color: #525bb7;
    width: 100%;
    z-index: 1000;
    padding-top: 0;
    padding-bottom: 0;
    justify-content: space-between!important;
    padding-right: 2rem;
    font-family: "Poppins";
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover .nav-link:active {
    color: #fff;
    text-decoration-line: underline;
    text-decoration-color: #4ff5dd;
    text-decoration-style: solid;
}

.btn_nav{
    border: 1.5px solid #4ff5dd;
    background-color:#4ff5dd ;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0.5rem;
}
.btn_nav a{
    color: #525bb7!important;
    text-decoration: none;
    
}

.btn_nav a:active, .btn_nav a:hover {
    text-decoration-line: none!important;
}
.nav-item {
    font-size: 14px;
}
@media (max-width: 991px){
    .portrait{
      display: none!important;
    }
}
@media (min-width: 992px){
  .navbar-expand-lg .navbar-collapse {
    justify-content: flex-end;
  }
}
</style>