<template>
  <div class="home">
    <Menu2></Menu2>
    <Menu/>
    <section id="inicio_home">
      <div class="container-first">
        <div class="first-title">
          <h1>Prepara <br>a tu equipo para los <br>desafíos de hoy.</h1>
          <p class="portrait">Alcanza tus objetivos profesionales y personales <br> con cursos 100% online hechos para ti.</p>
          <div class="btn_nav1">
            <router-link to="/#cursos" class="nav-link">Ver cursos</router-link>
          </div>
        </div>
      </div>
    </section>
    <div>
        <img class="computador" src="../Imagenes/computadores.png" alt="computadores">
    </div>
    <section id="second">
      <div class="container-second">
        <div class="slider-companies" >
          <div class="second-title" id="quienes-somos">
            <p>Equipos que confian en nosotros</p>
          </div>
          <div class="slider-container-second" >
            <ul class="controls" id="customize-controls" aria-label="Carousel Navigation" tabindex="0">
              <li class="prev" data-controls="prev" aria-controls="customize" tabindex="-1">
                <img src="../Imagenes/flecha_atras.png" alt="prev">
              </li>
              <li class="next" data-controls="next" aria-controls="customize" tabindex="-1">
                <img src="../Imagenes/flecha_adelante.png" alt="prev">
              </li>
            </ul>
            <ul class="nav" id="first-slider">
              <li v-for="item in companies" :key="item.id">
                <img class="logoCompany" :src="item.company" alt="company">
              </li>
            </ul>
          </div>
        </div>
        <div class="second-content" >
          <div class="second-content-title">
            <h3>Cursos y carreras junto a expertos</h3>
          </div>
          <div class="second-box">
            <div class="second-content-1">
              <div class="second-content-paragraph">
                <p> <span style="font-weight: 600;">Altera Academy es una empresa que busca la vinculación entre la tecnología y las personas </span>por medio de cursos que brinden las herramientas necesarias para estar en la vanguardia tecnológica. <br> <br> Diseñamos nuestros cursos con profesionales expertos en sus áreas y estos te ayudarán a potenciar tus habilidades en tecnologías, diseño, RRSS entre otras.
                </p>
              </div>
            </div>
            <div class="second-content-2">
              <div class="One-paragraph">
                <div class="One-paragraph-img"> 
                  <img src="../Imagenes/icono_gorro.png" alt="">
                </div>
                <div class="One-paragraph-p">
                  <p>Desarrolla tus habilidades con expertos <br class="mobile"> en cada área que desees explorar.</p>
                </div>
              </div>
              <div class="One-paragraph">
                <div class="One-paragraph-img">
                  <img src="../Imagenes/icono_reloj.png" alt="">
                </div>
                <div class="One-paragraph-p">
                  <p>Nuestros cursos están disponibles las <br class="mobile"> 24 horas los 7 días de la semana.</p>
                </div>
              </div>
              <div class="One-paragraph">
                <div class="One-paragraph-img">
                  <img src="../Imagenes/icono_corona.png" alt="">
                </div>
                <div class="One-paragraph-p">
                  <p>Accede a contenidos exclusivos <br class="mobile"> y adicionales en cada curso.</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
    <section id="cursos">
      <div class="container-third">
        <div class="title-third">
          <h3>Cursos Disponibles</h3>
          <p>En distintas áreas de capacitación</p>
        </div>
        <div class="card-box">
          <a href="/cursos/?cat=administracion" class="card-container-third">
            <div class="card-third">
              <img src="https://www.altera.cl/img/imgAA/curso1.png" alt="">
              <div class="box-area-name">
                <p>Administración</p>
              </div>
              
            </div>
          </a>
          <!--<a href="/cursos/?cat=comercio-y-servicios-financieros" class="card-container-third">
            <div class="card-third">
              <img src="https://www.altera.cl/img/imgAA/curso4.png" alt="">
              <div class="box-area-name">
                <p>Comercio y servicios financieros</p>
              </div>
            </div>
          </a>-->
          <a href="/cursos/?cat=computacion-e-informatica" class="card-container-third">
            <div class="card-third">
              <img src="https://www.altera.cl/img/imgAA/curso7.png" alt="">
              <div class="box-area-name">
                <p>Computación e informática</p>
              </div>
            </div>
          </a>
          <a href="/cursos/?cat=marketing-y-redes-sociales" class="card-container-third">
            <div class="card-third">
              <img src="https://www.altera.cl/img/imgAA/curso3.png" alt="">
              <div class="box-area-name">
               <p>Marketing y Redes Sociales</p>
              </div>
            </div>
          </a>
          <!--<a href="/cursos/?cat=educacion" class="card-container-third">
            <div class="card-third">
              <img src="https://www.altera.cl/img/imgAA/curso8.jpg" alt="">
              <div class="box-area-name">
                <p>Educación</p>
              </div>
            </div>
          </a>-->
          <a href="/cursos/?cat=idiomas" class="card-container-third">
            <div class="card-third">
              <img src="https://www.altera.cl/img/imgAA/curso5.png" alt="">
              <div class="box-area-name"> 
                <p>Idiomas</p>
              </div>
            </div>
          </a>
          <a href="/cursos/?cat=servicio-a-las-personas " class="card-container-third">
            <div class="card-third">
              <img src="https://www.altera.cl/img/imgAA/curso9.png" alt="">
              <div class="box-area-name">
                <p>Servicio a las personas </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </section>
    <section id="fourth">
      <div class="container-fourth">
        <div class="fourth-one">
          <h2>Estudia <br>desde cualquier <br>parte de mundo.</h2>
          <p>Sin importar donde estés, siempre <br> puedes acceder a nuestros cursos <br> desde tu celular o Tablet.</p><br>
        </div>
        <div class="fourth-two">
          <img src="../Imagenes/mapa.png" alt="Altera Academy en el mundo ">
        </div>
      </div>
    </section>
    <section id="servicios">
      <div class="container-fifth">
        <div class="box-fifth">
          <h2>Servicios empresas.</h2>
          <p>Cursos se adaptan a las necesidades de cada cliente.</p><br> 
        </div>
        <div class="fifth-two">
          <div class="slider-container-second" >
            <ul class="controls" id="customize-controls2" aria-label="Carousel Navigation" tabindex="0">
              <li class="prev" data-controls="prev" aria-controls="customize" tabindex="-1">
                <img src="../Imagenes/flecha_atras.png" alt="prev">
              </li>
              <li class="next" data-controls="next" aria-controls="customize" tabindex="-1">
                <img src="../Imagenes/flecha_adelante.png" alt="prev">
              </li>
            </ul>
            <ul class="nav" id="third-slider">
              <li class="third-ghost_card">
                <div class="card-service">
                  <router-link to="/servicios#eLearning" class="card-service-img">
                    <img src="../Imagenes/servicios/iconos1.png" alt="servicio1">
                  </router-link >
                  <div>
                    <p>Cursos <br> E-learning</p>
                  </div>
                </div>
              </li>
              <li class="third-ghost_card">
                <div class="card-service">
                  <router-link to="/servicios#dnc" class="card-service-img">
                    <img src="../Imagenes/servicios/iconos4.png" alt="servicio1">
                  </router-link>
                  <div>
                    <p>DNC</p>
                  </div>
                </div>
              </li>
              <li class="third-ghost_card">
                <div class="card-service">
                  <router-link  to="/servicios#microLearning" class="card-service-img">
                    <img src="../Imagenes/servicios/iconos2.png" alt="servicio1">
                  </router-link>
                  <div>
                    <p>Cursos <br> Micro e-learning</p>
                  </div>
                </div>
              </li>
              <li class="third-ghost_card">
                <div class="card-service">
                  <router-link to="/servicios#customizado" class="card-service-img">
                    <img src="../Imagenes/servicios/iconos5.png" alt="servicio1">
                  </router-link>
                  <div>
                    <p>Creación de cursos <br> customizados </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="btn_nav2">
          <router-link to="/servicios">Ver más</router-link>  
        </div>
      </div>
    </section>
    <section id="blog_" >
      <div class="slider-network" >
          <div class="slider-container-six" >
            <ul class="controls" id="customize-controls1" aria-label="Carousel Navigation" tabindex="0">
              <li class="prev" data-controls="prev" aria-controls="customize" tabindex="-1">
                <img src="../Imagenes/flecha_atras1.png" alt="prev">
              </li>
              <li class="next" data-controls="next" aria-controls="customize" tabindex="-1">
                <img src="../Imagenes/flecha_adelante1.png" alt="prev">
              </li>
            </ul>
            <ul class="nav" id="second-slider">
              <li class="card-networks-li">
                <div class="card-networks">
                  <div class="card-networks-person">
                    <img src="../Imagenes/citas/MA.png" alt="">
                  </div>
                  <div class="card-networks-content">
                    <img src="../Imagenes/citas/5est.png" alt="estrellas AA">
                    <h5>La plataforma de Altera Academy es muy amigable y practica para poder enseñar idiomas, muy recomendable.</h5>
                    <p>Marie Andy Sanozier <span>• Instructora del curso de Kreyol</span></p>
                  </div>
                </div>
              </li>
              <li class="card-networks-li">
                <div class="card-networks">
                  <div class="card-networks-person">
                    <img src="../Imagenes/citas/JPC.png" alt="">
                  </div>
                  <div class="card-networks-content">
                    <img src="../Imagenes/citas/4est.png" alt="estrellas AA">
                    <h5>Se destaca su profesionalismo y que siempre el participante es prioridad al momento de crear contenido.</h5>
                    <p>Juan Pablo Castro <span>• Instructor del curso de BCP</span></p>
                  </div>
                </div>
              </li>
              <li class="card-networks-li">
                <div class="card-networks">
                  <div class="card-networks-person">
                    <img src="../Imagenes/citas/RS.png" alt="">
                  </div>
                  <div class="card-networks-content">
                    <img src="../Imagenes/citas/5est.png" alt="estrellas AA">
                    <h5>¡¡¡El contenido me sirvió mucho en mi trabajo… excelente profe!!!.</h5>
                    <p>Ronald Salazar <span>• Participante del curso de Kreyol</span></p>
                  </div>
                </div>
              </li>
              <li class="card-networks-li">
                <div class="card-networks">
                  <div class="card-networks-person">
                    <img src="../Imagenes/citas/MF.png" alt="">
                  </div>
                  <div class="card-networks-content">
                    <img src="../Imagenes/citas/5est.png" alt="estrellas AA">
                    <h5>Me gusto el contenido super fácil de entender.</h5>
                    <p>Margarita Flores <span>• Participante del curso técnicas en trabajo en equipo</span></p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
      </div>
      <div class="container-sixth"></div>
    </section>
    <section id="seventh">
      <div class="container-seventh" style="display:none">
        <h2>Tendencias para tu crecimiento</h2><br><br>
        <img src="../Imagenes/blog_provisional.png" alt="blog Altera Academy">
        <div class="btn_nav">
          <a  href="" class="nav-link">Ver el blog</a>
        </div>
      </div>
    </section>
    <section id="contacto_">
      <div class="container-eight">
        <div class="eight-content">
          <h2>Únete a Altera Academy <br>y potencia tus conocimientos.</h2>
          <div class="btn_nav1">
            <router-link to="/#cursos" class="nav-link">Ver cursos</router-link>
          </div><br>
          
          <div class="contact-icons-eight">
            <p style="font-size:14px"><span style="font-weight: 600;">¿Tienes dudas?</span> Contáctanos</p><br>
            <a href="tel:+56442380238">
              <img src="../Imagenes/icono_telefono.png" alt="Contáctanos teléfono Altera">
            </a>
            <a @click="showSendModal">
              <img src="../Imagenes/icono_mail.png" alt="Contáctanos mail Altera">
            </a>
        </div>
        </div>
    <div v-show="showModal" class="modal-bc-contact">
      <div class="modal-container">
        <a @click="showSendModal" class="ClosePopUp">
          <img src="../Imagenes/cerrar.png" alt="cerrar-popUp">
        </a>
        <div class="contact-title">
          <div class="contact-title-box">
            <h5>Formulario de contacto</h5>
            <p>Ingresa tus datos y en breve nos comunicaremos contigo.</p>
          </div>
          <div class="logo-box">
            <img src="../Imagenes/logo-gris.png" alt="Logo Altera">
          </div>
        </div>
        <div class="contact-content">
          <div class="content-box1">
            <div>
              <p>Nombre</p>
              <input type="text" v-model="name" name="name" placeholder="Ingresa tu nombre">
            </div>
            <div>
              <p>Mail</p>
              <input type="text" v-model="email" name="email" placeholder="Ingresa tu mail">
            </div>
            <div>
              <p>Teléfono</p>
              <input type="text" v-model="phone"  name="phone" placeholder="Ingresa tu número">
            </div>
          </div>
          <div class="content-box2">
            <div>
              <p>Mensaje</p>
              <textarea name="" v-model="message"  cols="30" rows="10" placeholder="¿Cómo podemos ayudarte?"></textarea>
            </div>
          </div>
        </div>
        <div class="btns-contact">
          <input @click="checkForm" type="submit" value="Enviar">
        </div>
        <div class="error">
            <p v-if="errors.length">
              <ul>
                  <li v-for="(error,index) in errors" :key="index">{{ error }}</li>
              </ul>
            </p>
          </div>
        </div>
    </div>
        <div class="eight-image">
          <img src="../Imagenes/ilustracion_contacto.png" alt="Contacto">
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import Menu from '@/components/Menu.vue';
import Menu2 from '@/components/Menu2.vue';
import Footer from '@/components/Footer.vue';
import {tns} from '../../node_modules/tiny-slider/src/tiny-slider';
import axios from 'axios';


export default {
  name: 'Home',
  components: {
    Menu,
    Menu2,
    Footer,
  },
  data(){
    return{
      showModal: false,
      search: '',
      name: '',
      email: '',
      subject: '¿Tienes dudas? Contáctanos',
      message: '',
      phone:'',
      errors: [],
      regxEmail: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      regxPhone:/[^0-9\\+]/g,
      companies:[
        {company:"https://www.altera.cl/img/imgAA/1afc.jpg"},
        {company:"https://www.altera.cl/img/imgAA/2avon.jpg"},
        {company:"https://www.altera.cl/img/imgAA/3dental.jpg"},
        {company:"https://www.altera.cl/img/imgAA/4soprole.jpg"},
        {company:"https://www.altera.cl/img/imgAA/5sodexo.jpg"},
        {company:"https://www.altera.cl/img/imgAA/6sams.jpg"},
        {company:"https://www.altera.cl/img/imgAA/7starken.jpg"},
        {company:"https://www.altera.cl/img/imgAA/8copec.jpg"},
        {company:"https://www.altera.cl/img/imgAA/9unired.jpg"},
        {company:"https://www.altera.cl/img/imgAA/10ahumada.jpg"},
      ]
    }
  },

  methods: {

  showSendModal(){
		this.showModal = !this.showModal
	},
  renderSlider1() {
      var slider1 = document.getElementById('first-slider')
        if(slider1) {
            tns({
              container: '#first-slider',
              loop: true,
              items: 1,      
              navPosition:"bottom",
              speed: 500,
              mouseDrag: true,
              controlsContainer: "#customize-controls",
              responsive: {
                420: {
                  items: 3,
                  },
                650: {
                  items: 4,
                  },
                1200: {
                  items: 5,
                  }
                }
            })
          }
        },
  renderSlider2() {
      var slider2 = document.getElementById('second-slider')
        if(slider2) {
            tns({
              container: '#second-slider',
              loop: true,
              items: 1,      
              navPosition:"bottom",
              speed: 500,
              mouseDrag: true,
              controlsContainer: "#customize-controls1",
            })
          }
        },
  renderSlider3() {
      var slider2 = document.getElementById('third-slider')
        if(slider2) {
            tns({
              container: '#third-slider',
              loop: true,     
              navPosition:"bottom",
              speed: 500,
              mouseDrag: true,
              controlsContainer: "#customize-controls2",
              responsive: {
                720: {
                  items: 2,
                  },
                820: {
                  items: 3,
                  },
                950: {
                  items: 4,
                  },
                }
            })
          }
        },
  sendInformation(){
    // Mostrar mensaje "Enviando..."
    let data = {
        "to": "contacto@alteraacademy.com", 
        "from": this.email,
        "sender":this.name,
        "subject": this.subject,
        "phone": this.phone,
        "msg":  this.subject + '<br>' +'Nombre: ' + this.name + ' <br> Correo: ' + this.email + ' <br> Teléfono: ' + this.phone + ' <br> Mensaje: ' + this.message,
        }
        axios
        .post('https://sender.alteracloud.com/send',data)
        .then((response)=>{
          console.log(response.data);
          this.showModal = !this.showModal;
          this.email="";
          this.name="";
          this.message="";
          this.phone="";
          this.$router.push({ name: "confirmacion"})
        })
        .catch((error)=>{
          // Si esto ocurre mostrar un mensaje de "Fallo en el servidor", el cual deberia desaparecer unos segundos despues
          console.log(error)
          })
        },
        checkForm() {
            // Falta bloquear el boton cuando se está procesando y desbloquearlo cuando termine.
            this.errors = [];
            if ((!this.name)||(!this.email)||(!this.message)||(!this.phone))
            {
                this.errors.push('Uno o más campos presentan un error');
            } 
            if (this.name.length <6 || this.email.length <6 || this.message.length <6 || this.phone.length <9 ) {
                this.errors.push('Sus datos deben tener como  mínimo 5 caracteres');
            }
            if (!this.errors.length) {
                this.sendInformation();
            } else {
                return false;
            }
        }
   },
  mounted() {
    this.renderSlider1()
    this.renderSlider2()
    this.renderSlider3()
  },
}
</script>
<style scoped>
/* ----------------------------------------------------------------------------------------
/*first seccion*/
.home{
  font-family: "Poppins";
}
.container-first{
  padding: 2rem 2rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 30%;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: initial;
  justify-content: space-between;
}
.container-first p{
  color: #212643;
  font-weight: 600;
  font-size: 18px;
}

.container-first h1{
  font-weight: 800;
  margin-bottom: 3rem;
  margin-top:5rem;
  color: #5459c1;
}

.first-title{
  width: 100%;
  text-align: center;
}

.computador{
  width: 45rem;
  z-index: 21;
  position: inherit;
  margin-top: -12%;
}

.btn_nav1{
    border: 1.5px solid #5459c1;
    background-color:#5459c1;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0.5rem;
    width: 12rem;
    margin-top: 4rem;
}
.btn_nav1 a{
    color: #ffff!important;
    font-weight: 600;
    font-size: 16px;
    text-decoration: none;
}

/*second seccion*/
.container-second{
  background-color: #fff;
  overflow: hidden;
  width: 100%;
  margin-top: -13%;
}
.second-title{
  color:#868686;
  padding-top: 10rem;
  font-size: 16px;
  font-weight: 600;
}
.logoCompany{
  width:7rem;
}

.slider-companies{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.slider-container-second{
  margin: 4rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
}
.second-content-paragraph{
  max-width: 350px;
  text-align: justify;
}
.second-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  color: #555970;
}
.second-box{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.second-content-title h3{
  color: #5459c1;
  font-weight: 800;
  margin-bottom: 5rem;
  margin-top: 2rem;
}

.One-paragraph{
  display: flex;
  flex-direction: column;
  justify-content: initial;
  align-items: center;
  margin-bottom: 1rem;
}
.One-paragraph-p{
  text-align: center;
}
.One-paragraph-p p{
  margin-bottom: 0;
  font-size: 14px;
}
.One-paragraph-img{
  border: 2px solid #4ff5dd;
  border-radius: 40px;
}
.One-paragraph-img img{
  width: 4em;
  padding: 0.5em;
}
ul#customize-controls2.controls,
ul#customize-controls1.controls,
ul#customize-controls.controls{
  padding: 0;
  width: 90%;
}

.controls {
  list-style: none;
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;    
  justify-content: space-between;
}


/*third seccion*/
.container-third{
  background-color: #757acc;
  padding: 3rem 2rem ;
}
.title-third{
  color: #fff;
  padding-bottom: 4rem;
}
.title-third h1{
  font-size: 70px;
  font-weight: 900;
}
.title-third h3{
  font-weight: 600;
  font-size: 30px
}
.card-box{
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}
.card-third{
  background-color: #fff;
  max-width: 280px;
  padding: 1rem;
  border-radius: 18px;
  margin: 1rem;
}

.box-area-name{
  height: 80px;
}
.card-third p{
  margin-bottom: 0;
  padding: 1.5rem 0rem;
  color: #787dce;
  font-weight: 600;
}
.card-third img{
  max-width: 250px;
  border-radius: 12px;
}
.card-box a{
  text-decoration: none;
}

/*fourth seccion*/
.container-fourth{
  background-color: #e02c6d;
  padding: 7rem 1rem;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container-fourth img{
  width: 22rem;
}
.fourth-one{
  text-align: center;
  max-width: 350px;
}
.fourth-one h2{
  font-weight: 600;
  padding-bottom: 1rem;
}
.fourth-one p{
  font-size: 14px ;
}
.fifth-one-paragraph{
  max-width: 350px;
}

/*fifth seccion*/
.container-fifth{
  padding: 7rem 1rem;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.box-fifth{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.box-fifth h2{
  font-weight: 600;
  color: #5459c1;
}
.box-fifth p{
  font-weight: 600;
  color: #838695
}
.container-fifth .btn_nav{
  width: 8rem;
}
.fifth-one-paragraph h2{
  padding-bottom: 1rem;
}
.fifth-two{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
.fifth-two .controls img{
  width: 2rem;
  margin: 0rem 0.5rem;
}
.card-service img{
  width: 4rem;
  padding: 0.1em;
}

.card-service-img{
  padding: 1.5rem;
  border-radius: 70px;
  border: 2px solid #4ff5dd ;
  margin-bottom: 1rem;
  width: fit-content;
  background-color: #4ff5dd ;
}

.card-service p{
  font-weight: 600;
  color: #838695;
  font-size: 14px;
}
.third-ghost_card{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.card-service{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btn_nav2{
    border: 1.5px solid #5459c1;
    background-color:#5459c1 ;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0.5rem;
    padding: 0.5em 3em;
}
.btn_nav2 a{
    color: #fff;
    font-size: 14px;
    text-decoration: none;
}

/*sixth seccion*/
#blog_{
  background-image: url(../Imagenes/citas.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 6rem;
}
.slider-network{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container-sixth{
  height: 80vh;
}

#customize-controls1 img{
  width: 1.5rem ;
}
.card-networks{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
}
.card-networks-person{
  border: 1px solid #fff;
  border-radius: 80px;
  background-color: #fff;
  margin-bottom: 1rem;
}
.card-networks-person img{
  width: 7rem;
}
.card-networks-content p{
  color:#838695;
  font-weight: 800;
  font-size: 11px;
}
.card-networks-content p span{
  color:#838695;
  font-weight: 600;
  font-style: italic;
}

.card-networks-content{
  background-color: #fff;
  border-radius: 20px;
  padding: 1rem 1.5rem;
  color:#4f56b8;
}
.card-networks-content h5{
  font-weight: 800;
  margin-bottom: 1rem;
}

.slider-container-six{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/*seventh seccion*/
.container-seventh{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10rem 1rem;
}

/*eight seccion*/
.container-eight{
  background-color: #41455d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem 1rem 0rem 1rem;
  color: #fff;
}
.eight-content h2{
  font-weight: 600;
}
.contact-icons-eight{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}
.contact-icons-eight a{
  cursor:pointer;
}
.contact-icons-eight img{
    margin-left: 1rem;
}
.eight-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.eight-content .btn_nav1{
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  background-color:#4ff5dd;
  border:1px solid #4ff5dd;
}
.eight-content .btn_nav1 a{
  color: #4f56b8!important;
}
.contact-icons-eight img{
  width: 2.5rem;
  margin-bottom: 2rem;
  border-radius: 20px;
}
.eight-image img{
  width: 14rem;
}
.ClosePopUp{
  width: 102%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.ClosePopUp img{
  width: 2rem;
  margin-top: -15px;
}
.modal-bc-contact{
  position: fixed;
  z-index: 900;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal-container{
  background-color: #fff;
  border-radius: 30px;
}
.modal-container input{
  background-color: #dddd;
  border: 1px solid #dddd;  
  border-radius: 20px;
  padding: 0.2rem 1rem;
  margin-bottom: 1rem;
  width: 250px;
}
.modal-container textarea{
  background-color: #dddd;
  border: 1px solid #dddd;  
  border-radius: 20px;
  padding: 0.2rem 1rem;
  height: 110px;
  width: 250px;
}
.modal-container input::placeholder,
.modal-container textarea::placeholder{
  font-size: 12px;
}
.contact-title{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: initial;
  padding: 0.5rem 2.5rem 1rem 2.5rem;
}
.contact-title-box h5{
  color: #6154b4;
  font-weight: 600;
}
.contact-title-box p{
  color: #808393;
  font-size: 10px;
}
.contact-content{
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: initial;
  padding: 0rem 2.5rem 0rem 2.5rem;
}
.contact-content p{
  color:#4d4d4e ;
  margin-bottom: 5px;
  font-size: 12px;
}
.logo-box img{
  width: 2rem;
}
.btns-contact{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.btns-contact input{
  border: 1.5px solid #4ff5dd;
  background-color:#4ff5dd ;
  border-radius: 20px;
  width: 100px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #525bb7;
  font-weight: 600;
}
.btns-box{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.contact-footer{
  display: flex;
  flex-direction:column;
  justify-content: flex-end;
  align-items: flex-end;
  height: 2rem;
  background-color: #6154b4;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px; 
}
.error{
   width: 100%;
   background-color: #f2f2f2;
   font-size: 10px;
   margin-bottom: 2rem;
}
.error ul li{
  list-style: none;
  color: #000;
  text-align: center;
}

textarea:active,
textarea:focus,
input:active,
input:focus {
    outline: 0;
    box-shadow: none
}

@media (max-width: 360px){
  .container-first h1{
    font-size: 23px;
  }
  .second-title {
    padding-top: 5rem;
  }
  .container-fourth img{
    width: 18rem;
  }
}

@media (min-width: 992px){
.mobile{
    display: none!important;
  }
/*first seccion*/
  .first-title{
    width: 60%;
    text-align: left;
    padding-left: 8rem;
    padding-top: 6rem;
  }
  .container-first{
    background-image: url(../Imagenes/fondo.jpg);
    height: 140vh;
  }
  .container-first h1{
    font-size: 45px;
    margin-top: 3rem;
  }
  .first-image{
    width: 50%;
  }

   /*second seccion*/
  .container-second{
    padding: 10rem 10rem;
  }
  .second-content{
    margin-top: 4rem;
  }
  .second-box{
    flex-direction: row;
  }
  .second-content-1{
    width: 60%;
  }
  .second-content-2{
    width: 40%;
  }
  .second-content-paragraph{
    width: 70%;
  }
  .One-paragraph{
    flex-direction: row;
  }
  .One-paragraph-p{
    text-align: left;
  }
  .One-paragraph-p p {
    padding-left: 2rem;
  }
  .slider-container-second{
    width: 70%;
  }
  ul#customize-controls1.controls,
  ul#customize-controls.controls{
    width: 60%;
  }

  /*third seccion*/
  .container-third{
    padding: 5rem 9rem;
  }
  
  .card-service:hover img{
    width: 4.2rem;
  }

  /*fourth seccion*/
  .container-fourth{
    flex-direction: row;
  }
  .container-fourth img{
    width: 30rem;
  }
  .fourth-one{
    text-align: justify;
  }

  /*fifth seccion*/
  .container-fourth{
    padding: 7rem 6rem;
  }

  .fifth-one-paragraph{
    text-align: justify;
    max-width: 350px;
  }

  /*sixth seccion*/

  .slider-container-six .controls{
    width: 50%;
  }
  .card-networks{
   flex-direction: row;
  }
  .card-networks-content{
    max-width: 400px;
  }
  .card-networks-person{
    margin-right: 2rem;
  }

   /*eight seccion*/
  .container-eight{
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
    text-align: justify;
    padding: 3rem 1rem 0rem 1rem;
  }

  .eight-content{
    display: flex;
    flex-direction: column;
    justify-items: initial;
    align-items: baseline;
  }
  .content-box2{
    margin-left:2rem ;
  }
  .contact-content{
    flex-direction:row;
  }
  .modal-container textarea{
    height: 180px;
  }
}
@media (max-width: 991px){
  .portrait{
    display: none!important;
  }
  .container-first{
    background-color: #d2d2d2;
  }
  .container-first h1{
    margin-top: 10rem;
  }
  .first-title .btn_nav1{
    margin-bottom: 10rem;
  }
  .first-title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .computador{
    width: 20rem;
    margin-top: -24%;
  }
  .container-second{
    padding: 1rem 1rem;
  }
  .second-content-paragraph{
    margin-bottom: 4rem;   
  }
  .One-paragraph-p{
    padding: 1rem 0rem;
    max-width: 350px;
  }
  .card-networks{
   text-align: center;
  }
  .card-networks-content{
    max-width: 240px;
    text-align: center;
  }
  .eight-image img{
    margin-top: 1rem;
  }
  .eight-content h2{
    margin-bottom: 1rem;
  }
  .logo-box{
    display: none;
  }
  .container-sixth{
    height: 40vh;
  }

}

</style>
