import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import axios from 'axios'
import style from '../public/style.css'

Vue.config.productionTip = false
Vue.use(BootstrapVue)

new Vue({
  style,
  axios,
  router,
  render: h => h(App)
}).$mount('#app')

