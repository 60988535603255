import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/cursos',
    name: 'Cursos',
    component: () => import('../views/Cursos.vue')
  },
  {
    path: '/servicios',
    name: 'Servicios',
    component: () => import('../views/Servicios.vue')
  },
  {
    path: '/form_service',
    name: 'Form_service',
    component: () => import('../views/form_service.vue')
  },
  {
    path: '/form_course',
    name: 'Form_course',
    component: () => import('../views/form_course.vue')
  },
  {
    path: '/form_job',
    name: 'Form_job',
    component: () => import('../views/form_job.vue')
  },
  {
    path: '/politicas',
    name: 'politicas',
    component: () => import('../views/politicas.vue')
  },
  {
    path: '/terminos',
    name: 'terminos',
    component: () => import('../views/terminos_condiciones.vue')
  },
  {
    path: '/confirmacion',
    name: 'confirmacion',
    component: () => import('../views/confirmacion.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to) {    
    if (to.hash) {      
      return {
      selector: to.hash,
      }
    }     
    else {
        return { x: 0, y: 0 }
      }
  }
})

export default router
