<template>
  <div class="mobile">
    <div>
        <nav @click="toggleMenu" :class="{open: open}" class="cbp-spmenu cbp-spmenu-vertical cbp-spmenu-left" id="cbp-spmenu-s1">
			<router-link @click="toggleMenu" to="/">Inicio</router-link>
			<router-link @click="toggleMenu" to="/#quienes-somos">Quienes somos</router-link>
			<router-link @click="toggleMenu" to="/#cursos">Cursos</router-link>
			<router-link @click="toggleMenu" to="/servicios">Servicios</router-link>
			<router-link @click="toggleMenu" to="/#blog_">Blog</router-link>
			<router-link @click="toggleMenu" to="/#contacto_">Contáctanos</router-link>
        </nav>
        <div class="container_menu2">
			<div class="logo-menu2">
				<router-link to="/"><img src="../Imagenes/logo-blanco.png" alt="logo"></router-link> 
			</div>
			<div id="showLeft" @click="toggleMenu">
				<div class='btn-line'>
					<div class="line1" :class="{open: open}"></div>
					<div class="line2" :class="{open: open}"></div>
					<div class="line3" :class="{open: open}"></div>
				</div>
			</div>
        </div>
	</div>
  </div>
</template>

<script>
export default {
name: 'Menu2',
props: { },
data() {
	return {
		open: false,
		Up:false
	}
},
methods: {
	toggleMenu() {
		if(!this.open){
			this.open=true
			this.Up=true
		}else{
			this.open=false
		}
		// this.open = !this.open
	}
}
}
</script>
<style scoped>
*:after,
*:before {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.clearfix:before,
.clearfix:after {
	content: " ";
	display: table;
}

.clearfix:after {
	clear: both;
}

body {
    color: #5258c0;
	font-family: "Poppins";
}

a {
	color: #f0f0f0;
	text-decoration: none;
	font-family: "Poppins";
}

a:hover {
	color: #000;
}

.container_menu2{
	display: flex;
	flex-direction: row;
	align-items: center;
    justify-content: space-between;
	width: 100%;
	position: fixed;
	background-color: #525bb7;
	padding: 0.5rem 1rem 0.5rem 0.5rem;
	z-index: 3;
}
.logo-menu2 a img{
	width: 8rem;
	margin-top: 0.5rem;
	margin-right: 0.5rem;
}

.cbp-spmenu {
	background: #5258c0;
	position: fixed;
	padding-top: 4rem;
}

.cbp-spmenu h3 {
	color: #afdefa;
	font-size: 1.9em;
	padding: 20px;
	margin: 0;
	font-weight: 300;
	background: #0d77b6;
}

.cbp-spmenu a {
	display: block;
	color: #fff;
	font-size: 1.1em;
	font-weight: 300;
}

.cbp-spmenu a:hover {
	background: #258ecd;
}

.cbp-spmenu a:active {
	background: #afdefa;
	color: #47a3da;
}

.cbp-spmenu-vertical {
	width: 290px;
	top: 0;
	z-index: 1000;
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.cbp-spmenu-vertical a {
	border-bottom: 1px solid #258ecd;
	padding: 0.8rem 3rem;
}

.cbp-spmenu-left {
	left: -294px;
}

.cbp-spmenu-left.open {
	left: 0px;
	text-align: left;
}


.cbp-spmenu{
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.btn-line{
	width: 3rem;
    height: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
	cursor: pointer;
	transition: all .5s ease-in-out;
}
.line1,.line2,.line3 {
	width: 2rem;
	height: 3px;
	background-color:#4ff5dd;
	margin: 4px;
	border-radius: 5px;
	box-shadow: 0 2px 5px rgba(255,101,47,0.2);
	transition: all .5s ease-in-out;
}

/*ANIMATION*/
.line1.open{
	transform: rotate(45deg) translate(0px,15px);
}
.line2.open{
	transform: translatex(-50px);
	background: transparent;
	box-shadow: none;
}

.btn-line .line3.open{
	transform: rotate(-45deg) translate(0px,-15px);
}

@media (max-width: 360px){
.cbp-spmenu-vertical {
	width: 260px;
	}
.cbp-spmenu-left {
	left: -264px;
	}
.fifth-two img{
    width: 3rem;
	}
}


@media (min-width: 992px){
    .mobile{
      display: none!important;
    }
}
@media screen and (max-height: 26.375em){

	.cbp-spmenu-vertical {
		font-size: 90%;
		width: 190px;
	}

	.cbp-spmenu-left{
		left: -190px;
	}
}
</style>